<div class="relative z-30">
  <div class="absolute top-0 right-0 mt-2 mr-2">
    <button type="button" (click)="onCloseClick()" class="text-icon-actions">
      <lis-icon [name]="'action_close-24'"></lis-icon>
    </button>
  </div>
</div>
<div>
  <div class="relative">
    <h4 class="border-b border-divider" [ngClass]="{ 'typo-h2 p-6': variant === 'default', 'typo-h1 h-16 px-6 flex justify-center flex-col': variant === 'extended' }">
      <ng-content select="#header"></ng-content>
    </h4>
    <div class="p-6 max-h-[80vh] overflow-y-auto">
      <div [ngClass]="{ 'bg-overlays-background-gray p-6': variant === 'extended' }">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
  <div class="p-2 border-t border-divider" *ngIf="isFooterVisible">
    <ng-content select="#footer"></ng-content>
  </div>
</div>
